<template>
  <b-card>
    <b-card-header>Detail User Reseller</b-card-header>
    <b-card-body>
      <b-row class="mb-4">
        <b-col sm="4">
          <h6>Name</h6>
          <div class="mb-3">
            <strong>{{ this.users.name }}</strong>
          </div>
          <h6>Email</h6>
          <div class="mb-3">
            <strong>{{ this.users.email }}</strong>
          </div>
          <h6>Nomor Handphone</h6>
          <div class="mb-3">
            <strong>{{ this.users.phone }}</strong>
          </div>
          <h6>Verifikasi KTP</h6>
          <div class="mb-3">
            <strong>{{ this.user_card.ktp_status }}</strong>
          </div>
          <h6>Verifikasi Selfie</h6>
          <div class="mb-3">
            <strong>{{ this.user_card.selfie_status }}</strong>
          </div>
          <h6>Verifikasi NPWP</h6>
          <div class="mb-3">
            <strong>{{ this.user_card.npwp_status }}</strong>
          </div>
        </b-col>
        <b-col sm="4">
          <h6>Full Address</h6>
          <div class="mb-3">
            <strong>{{ this.user_address.address }}</strong>
          </div>
          <h6>Provinsi</h6>
          <div class="mb-3">
            <strong>{{ this.user_address.province }}</strong>
          </div>
          <h6>Kota</h6>
          <div class="mb-3">
            <strong>{{ this.user_address.city }}</strong>
          </div>
          <h6>Kecamatan</h6>
          <div class="mb-3">
            <strong>{{ this.user_address.district }}</strong>
          </div>
          <h6>Kelurahan</h6>
          <div class="mb-3">
            <strong>{{ this.user_address.village }}</strong>
          </div>
          <h6>Kode Pos</h6>
          <div class="mb-3">
            <strong>{{ this.user_address.postal_code }}</strong>
          </div>
        </b-col>
        <b-col sm="4">
          <h6>Gold Balance</h6>
          <div class="mb-3">
            <strong>{{ this.users.gold_balance }}</strong>
          </div>
          <h6>Wallet Balance</h6>
          <div class="mb-3">
            <strong>{{ this.users.wallet_balance }}</strong>
          </div>
          <h6>Registration Date</h6>
          <div class="mb-3">
            <strong>{{ this.created_at }}</strong>
          </div>
          <h6>Organization</h6>
          <div class="mb-3">
            <strong>{{ this.organization }}</strong>
          </div>
          <h6>Reseller ID</h6>
          <div class="mb-3">
            <strong>{{ this.reseller_id }}</strong>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <vuetable
            ref="vuetable"
            @vuetable:load-error="handleLoadError"
            :api-url="apiUrl"
            :http-options="HttpOptions"
            :fields="fields"
            pagination-path=""
            :muti-sort="true"
            :sort-order="sortOrder"
            :append-params="moreParams"
            @vuetable:pagination-data="onPaginationData"
          >
            <template slot="name-slot" slot-scope="prop">
              <span>{{ prop.rowData.name }} <br> ({{ prop.rowData.email }})</span>
            </template>
            <template slot="organization-slot" slot-scope="prop">
              <span>{{ prop.rowData.organization === null ? '-' : prop.rowData.organization }}</span>
            </template>
            <template slot="actions-slot" slot-scope="prop">
              <div class="custom-actions">
                <button
                  class="btn btn-info"
                  v-if="$can('detail_voucher_reseller_transaction')"
                  @click="onAction('detail-item', prop.rowData, prop.rowIndex)"
                >
                  Detail
                </button>
              </div>
            </template>
          </vuetable>
          <div class="vuetable-pagination ui basic segment grid">
            <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

            <vuetable-pagination
              ref="pagination"
              @vuetable-pagination:change-page="onChangePage"
            ></vuetable-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
Vue.use(Vuetable);
export default {
  name: 'detail-user-reseller',
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  data() {
    return {
      users: [],
      user_card: [],
      user_address: [],
      created_at: '',
      organization: '',
      reseller_id: '',
      errors: {
        code: "",
        message: "",
        status: "",
      },
      apiUrl:
        process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-user-reseller?hashedId=${this.$route.params.id}`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      },
      sortOrder: [
        {
          field: "created_at",
          sortField: "voucher_orders.created_at",
          direction: "desc",
        },
      ],
      moreParams: {},
      fields: [
        {
          name: "created_at",
          title: "Date",
          titleClass: "center aligned",
        },
        {
          name: "type",
          title: "Voucher Type",
          titleClass: "center aligned",
        },
        {
          name: "invoice_number",
          title: "Invoice",
          titleClass: "center aligned",
        },
        {
          name: "status",
          title: "Transaction Status",
          titleClass: "center aligned",
        },
        {
          name: "bought",
          title: "Voucher Bought",
          dataClass: "right aligned",
          titleClass: "center aligned",
        },
        {
          name: "redeemed",
          title: "Voucher Redeemed",
          titleClass: "center aligned",
          dataClass: "right aligned",
        },
        // {
        //   name: "actions-slot",
        //   title: "Actions",
        //   titleClass: "center aligned",
        //   dataClass: "center aligned",
        // },
      ],
    }
  },
  created() {
    this.$http.get(`user-reseller/${this.$route.params.id}`).then((res) => {
      let result = res.data.data

      this.users = result.users
      this.user_card = result.user_card
      this.user_address = result.user_address
      this.created_at = result.created_at
      this.organization = result.organization
      this.reseller_id = result.reseller_id
    })
  },
  methods: {
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
  }
}
</script>
